.PageNotFound {
	background-color: var(--secondary_tint1);
	height: calc(100vh - 114px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.PageNotFound div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	gap: 1rem;
}

.error404Image {
	width: 40%;
	height: auto;
}

.PageNotFound h1 {
	font-family: var(--font_1);
	font-size: 40px;
	font-weight: 400;
	color: var(--primary);
	letter-spacing: 2px;
	margin: -12px 0 0 0;
}

.PageNotFound p {
	font-family: var(--font_2);
	font-size: 18px;
	font-weight: 400;
	color: var(--primary);
	letter-spacing: 2px;
	margin: 0px 28px 48px;
    text-align: center;
}

@media (max-width: 1080px) {
	.PageNotFound h1 {
		font-size: 32px;
	}
}
@media (max-width: 940px) {
	.error404Image {
		width: 80%;
	}
	.PageNotFound h1 {
		font-size: 36px;
	}
}
@media (max-width: 680px) {
	.error404Image {
		width: 80%;
	}
	.PageNotFound h1 {
		font-size: 28px;
	}
}
