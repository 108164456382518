.MenuPriceItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap : 0.2rem ;
    padding : 4px 6px ;
    border: 2px solid var(--secondary);
    text-align: center;
}

.MenuPriceItemName, .MenuPriceItemCost {
    font-size: 16px;
    font-family: var(--font_1);
    font-weight: 600;
    
}
