.HomeSection2Point {
    position: relative;
    background-color: rgb(from var(--secondary_tint2) r g b / 60%);
    padding : 8px 16px ;
    border-radius: 8px;
    font-size: 18px;
}

.HomeSection2PointContent{
    display: flex;
    align-items: center;
    gap : 1rem ;
}

.HomeSection2PointContent img {
    width : 38px ;
    height : 40px ;
}