.Menu {
	position: relative;
	background-color: var(--secondary_tint1);
}

.MenuSectionHeader {
	position: relative;
	background-image: url("../../../public/MenuBg.jpeg");
	background-size: cover;
	height: 40vh;
	background-repeat: no-repeat;
	font-size: 32px;
	color: var(--primary);
}

.MenuSectionHeaderOverlay {
	background: linear-gradient(
		to top,
		rgb(from var(--secondary_tint1) r g b / 100%),
		rgb(from var(--secondary_tint1) r g b / 90%),
		rgb(from var(--secondary_tint1) r g b / 75%),
		rgb(from var(--secondary_tint1) r g b / 30%)
	);
	width: 100%;
	height: 100%;
	position: absolute;
}

.MenuSectionHeaderContent {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: absolute;
	bottom: 8%;
	width: 100%;
	gap: 1rem;
}

.MenuSectionHeaderHeading {
	font-family: var(--font_2);
	font-size: 2.5rem;
	width: fit-content;
	border-bottom: 2px solid var(--accent_2_tint1);
	padding: 0rem 3rem;
}

.MenuSectionHeaderDescription {
	font-family: var(--font_1);
	font-size: 24px;
	width: 30vw;
	text-align: center;
}

.MenuSectionNavigateButtonsWrapper {
	display: flex;
	gap: 1.5rem;
	padding: 0.75rem 2rem;
	position: sticky;
	top: 64px;
	z-index: 10;
	background-color: var(--secondary_tint1);
	border-bottom: 2px solid rgb(from var(--primary) r g b / 75%);
	border-top: 2px solid rgb(from var(--primary) r g b / 75%);
}

.MenuSectionNavigateButton {
	font-family: var(--font_5);
	font-size: 18px;
	color: var(--primary);
	padding: 12px 20px;
	text-align: center;
	background-color: var(--accent_2);
	border-radius: 2px;
	box-shadow: 0px 0px 4px 0px rgb(from var(--primary) r g b);
	cursor: pointer;
}
.MenuSectionNavigateButton:hover {
	background-color: var(--accent_2_tint1);
	transform: scale(1.05);
}
.MenuSectionNavigateButton.Active {
	background-color: var(--accent_1);
	animation: MenuItemAnimation 100ms ease-in-out;
}

.MenuItemsSection {
	margin: 2rem 80px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 2rem;
}

.MenuItemsSectionWrapper {
	animation: MenuItemAnimation 1s ease-in-out;
}

@keyframes MenuItemAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@media (max-width: 768px) {
	.MenuItemsSection {
		margin: 2rem 6px;
	}

	.MenuSectionHeaderDescription {
		width: 80vw;
	}
	.MenuSectionNavigateButtonsWrapper {
		display: flex;
		gap: 1rem;
		padding: 0.75rem 2rem;
		flex-wrap: wrap;
		justify-content: center;
	}
}
