.Footer {
    position: relative;
    background-color: var(--accent_2);
    padding: 20px 0px;
    border-top: 1px solid var(--primary);
    
}

.FooterContainer {
    margin : 0 4em ;
    display: flex;
    justify-content: space-between;
    align-items: center ;
}

.FooterLogo {
    width : 180px ;
    height: 232px;
    align-self: center;
    cursor: pointer;
}

@media  (max-width: 1080px) {
    .FooterContainer {
    margin : 0 2em ;
    }

}

@media  (max-width: 940px) {
    .FooterContainer {
        margin : 0 2em ;
        justify-content: space-between;
    }    
}

@media  (max-width: 860px) {
    .FooterLogo{
        display: none;
    }    
}

@media  (max-width: 680px) {
    .FooterContainer {
        margin : 0 2em ;
        flex-direction: column;
        gap : 48px ;
        }    
}