.HomeSection4 {
    position: relative;
    background-image: url("./../../assets/section4_background_image.png");
    background-size: cover;
    background-position: 0 10% ;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.HomeSection4Overlay {
    position: absolute;
    width: 100%;
    height: inherit;
    background: linear-gradient(to left,rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0) );
}

.HomeSection4Content {
    position: relative;
    color: var(--secondary);
    width: 43%;
    align-self: flex-end;
    letter-spacing: 1px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap : 3rem ;
    align-items: center;
    padding: 3rem 0;
    margin: 0 4rem;
}

.HomeSection4Header {
    font-size:48px;
    text-align: center;
    font-weight: bolder;
    font-family: var(--font_2);
    text-shadow:
    -1px -2px 0 var(--primary),
     2px -1px 0 var(--primary),
    -1px 2px 0 var(--primary),
     2px 1px 0 var(--primary);

     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     gap: 1rem;


}

.HomeSection4Description {
    font-size:22px;
    font-family: var(--font_1);
    color: var(--primary);
    text-align: center;
    
}


@media (max-width: 1080px) {
    .HomeSection4Content{
        margin: 0 2rem;
    }

}

@media (max-width: 940px) {
    .HomeSection4Content{
        margin: 0 1.5rem;
    }
    .HomeSection4 {
        height : 680px ;
    }
    .HomeSection4Overlay{
    background: none;
    background-color: rgb(from var(--secondary_tint1) r g b / 65%);

    }

    .HomeSection4Content {
        width: fit-content;
        padding: 3rem;
    }
}

@media (max-width: 680px) {
    .HomeSection4 {
        height : 780px ;

    }
    .HomeSection4Content {
        padding: 1rem;
    }

}