.App {
    position: relative;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    z-index: 1;
}

a {
    text-decoration: none;
}


@media (max-width: 440px) {
    .App {
        width : 440px ;
        height : max(100% ,620px) ;
    }
}


