@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Kalam&family=Kaushan+Script&family=Poppins:ital@0;1&family=Port+Lligat+Sans&display=swap");

* {
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 0;
}

body {
	margin: 0;
	padding: 0;
}

* {
	transition: all 300ms ease-in-out;
}

.Theme {
	--accent_1: #990000;
	--accent_1_tint1: #ff0000;

	--accent_2: #006633;
	--accent_2_tint1: #009246;

	--primary: #ffffff;
	--primary_tint1: #999999;
	--primary_tint2: #dddddd;

	--secondary: #000000;
	--secondary_tint1: #1e1e1e;
	--secondary_tint2: #333333;

	--font_1: "Bubblegum Sans", sans-serif;
	--font_2: "Kaushan Script", cursive;
	--font_3: "Kalam", cursive;
	--font_4: "Poppins", sans-serif;
	--font_5: "Port Lligat Sans", sans-serif;
}
