.Features {
    position: relative ;
    background-color: var(--secondary_tint2);
    padding: 64px 0px;
}

.FeaturesContent {
    display: flex;
    justify-content: space-evenly;
    background-color: transparent;
}

@media (max-width: 740px) {
    .FeaturesContent{
        flex-direction: column;
        align-items: center;
        gap : 56px ;
    }
    
}