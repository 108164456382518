.FeaturesSectionCard {
    position: relative;
    width : 280px ;
    height:320px;
    padding : 40px 16px ;
    border-radius: 24px;
    background-color: var(--primary_tint2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    opacity: 0.9;
    color : var(--secondary) ;
    border: 2px solid var(--secondary);
    box-shadow: 2px 4px 8px 0px var(--secondary);

}

.FeaturesSectionCard:hover {
    opacity: 1;
    transform: scale(1.02);
    cursor: pointer;
    box-shadow: 3px 6px 12px 1px var(--secondary) ;
    background-color: var(--primary)

}

.FeaturesSectionCardCardHeader {
    font-family: var(--font_2);
    font-size: 40px;
    line-height: 32px;
}

.FeaturesSectionCardCardContent {
    font-family: var(--font_1);
    letter-spacing: 1px;
    font-size: 20px ;
    line-height: 26px;
}
