.MenuSectionHead {
	position: relative;
    height: fit-content;
    padding : 0.5rem ;
    display: flex;
    flex-direction: column;
    gap : 0.5rem ;
    align-items: center;
    background-color: var(--secondary_tint2);
    color : var(--primary) ;
    margin-bottom: 2rem;
}

.MenuSectionHeadHeading {
    font-family: var(--font_2);
    padding : 0 40px 0px ;
    font-size: 28px ;
    width : fit-content ;
    border-bottom: 2px solid var(--accent_2_tint1);

}

.MenuSectionHeadDescription {
    font-family: var(--font_1);
    font-size: 18px;

}
