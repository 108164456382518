.MenuSectionCard {
	position: relative;
	display: flex;
	background-color: rgb(from var(--primary) r g b / 90%);
	width: 560px;
	height: 160px;
	border-radius: 1rem;
	gap: 0.5rem;
	overflow: hidden;
	box-shadow: 0px 0px 8px 0px rgb(from var(--primary_tint1) r g b);
	cursor: pointer;
}

.MenuSectionCard:hover {
	transform: scale(1.025);
}

.MenuSectionCardItemName {
	padding-top: 4px;
	font-size: 24px;
	font-family: var(--font_2);
	display: block;
	font-weight: 600;
}
.MenuSectionCardItemDescription {
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
	font-family: var(--font_3);
}

.MenuSectionCardPrices {
	display: flex;
	width: 96%;
	justify-content: space-around;
	padding: 12px 4px;
}

.MenuSectionCardImage {
	width: 160px;
	height: 100%;
}

.MenuSectionCardContentWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
        width: 100%;
	}

@media (max-width: 768px) {
	.MenuSectionCardItemName {
		padding-top: 4px;
		font-size: 22px;
	}
	.MenuSectionCardPrices {
		padding: 0px;
	}
	.MenuSectionCardContentWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
        width : 100% ;
	}
}
