.Home {
	position: relative;
}

.HomePageBackgroundImgContainer {
    width: 100%;
	height: calc(90vh + 8px) !important;
	/* background-image: url("./../../assets/back_ground.gif");
    background-size: cover; */
	position: fixed;
	z-index: -10;
}

.home_background_animation {
	width: inherit;
	
}

@media  (max-width: 1180px) {
    .home_background_animation {
	width: 1180px;	
}
}