.FooterContactUsContainer {
    position: relative;
    color : var(--primary) ;
    width : 300px ;
    display: flex;
    flex-direction: column;
    gap : 20px ;    
}

.FooterContactUsHeader {
    font-family: var(--font_2);
    font-size: 24px;
    width : max-content ;
    border-bottom: 2px solid var(--primary);
    padding: 0 28px 4px  0;
    margin-bottom: 12px;
}

.FooterContactUsContainerCallUsSet,
.FooterContactUsContainerEmailUsSet,
.FooterContactUsContainerLocationSet {
    display: flex;
    align-items: center;
    gap : 16px ;
    color: var(--primary);
}

.FooterContactUsContainerCallUsSet:hover,
.FooterContactUsContainerEmailUsSet:hover,
.FooterContactUsContainerLocationSet:hover {
    background-color: var(--secondary_tint1);
    padding: 0px 8px;
    border-radius: 4px;
}

.FooterContactUsContainerCallUsSet .CallUsImg {
    width : 28px ;
    height : 28px ;
}

.FooterContactUsContainerEmailUsSet .EmailUsImg{
    width : 28px ;
    height: 20px;
}

.FooterContactUsContainerLocationSet .LocationPinImg {
    width : 22px;
    height : 32px ;
    padding-right: 6px;
}

.FooterContactUsContainerCallUsHeader,
.FooterContactUsContainerEmailUsHeader,
.FooterContactUsContainerLocationHeader {
    font-family: var(--font_2);
    margin-bottom: 4px;
}

.FooterContactUsContainerCallUsData {
    font-family: var(--font_1);
    font-size: 28px;
}

.FooterContactUsContainerEmailUsData,
.FooterContactUsContainerLocationData{
    font-family: var(--font_1);
    font-size: 24px;   
}


@media  (max-width: 680px) {
    .FooterContactUsHeader {
        align-self: center;
        padding: 8px 32px;    
    }

    .FooterContactUsContainerCallUsSet:hover,
    .FooterContactUsContainerEmailUsSet:hover,
    .FooterContactUsContainerLocationSet:hover {
        background-color: transparent;
        padding: 0px 0px;
        border-radius: 4px;
    }

    .FooterContactUsContainer {
        gap : 32px ;    
    }
     
}

