.CTAButton {
    position: relative;
    padding : 12px 32px ;
    font-family: var(--font_4);
    font-size: 16px ;
    font-weight: 1000;
    letter-spacing: 1px;
    border-radius: 8px;
    border: 2px solid var(--primary);
    color: var(--primary);
    box-shadow: 0px 0px 8px 2px var(--secondary);
    width: max-content;
    cursor: pointer;

}

.Green {
    background-color: var(--accent_2);
}

.Green:hover {
    background-color: var(--accent_2_tint1);
}

.Red{
    background-color: var(--accent_1);
}

.Red:hover {
    background-color: var(--accent_1_tint1);
}

.CTAButton:hover {
    transform: scale(1.03);
}

.CTAButton:active {
    transform: scale(0.95);
    transition: 50ms ease-in-out;
    background-color: var(--secondary_tint1);
}