.FooterOpensHoursContainer {
    position: relative;
    color : var(--primary) ;
    width : 280px ;
    display: flex;
    flex-direction: column;
    gap : 16px ; 
}

.FooterOpenHoursHeader {
    font-family: var(--font_2);
    font-size: 24px;
    width : max-content ;
    border-bottom: 2px solid var(--primary);
    padding: 0 4px 4px 28px;
    margin-bottom: 16px;
    align-self: flex-end;
}

.FooterOpenHoursDayDataContainer {
    display: flex;
    justify-content: space-between;
    font-family: var(--font_1);
    font-size: 18px;
    letter-spacing: 1px;
    opacity: 0.25 ;
    padding : 6px 2px ; 
}
.FooterOpenHoursDayDataContainer:hover {
    background-color: var(--secondary_tint1);
    opacity: 1;
}

.FooterOpenHoursDayDataContainer.Active{
    opacity: 1;
    transform: scale(1.07);
}

.FooterOpenHoursDayName{
    width : 30% ;
}

.FooterOpenHoursContent {
    display: flex;
    flex-direction: column;
    gap : 4px ;

}


@media  (max-width: 680px) {
    .FooterOpenHoursHeader {
        align-self: center;
        padding: 8px 32px;    
    }
     
}