.ContactUsCard {
    position: relative;
    width : 280px ;
    height : 360px ;
    background-color: rgb(from var(--secondary) r g b / 50%);
    box-shadow: 0px 0px 12px rgb(from var(--primary_tint1) r g b / 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 0 ;
    box-sizing: border-box;
    justify-content: space-between;
    border-radius: 1.5rem;
    cursor: pointer;
    opacity: 0.85;

}

.ContactUsCard:hover {
    transform: scale(1.025);
    box-shadow: 0px 0px 16px 2px rgb(from var(--primary_tint1) r g b / 75%);
    opacity: 1;
}

.ContactUsCardImageContainer {
    background-color: var(--accent_2);
    height : 64px ;
    width: 64px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ContactUsCardCallIcon,
.ContactUsCardClockIcon {
    width : 32px;
    height: 32px;
}
.ContactUsCardEmailIcon {
    width : 28px;
    height: auto;
}

.ContactUsCardLocationIcon {
    width : 20px;
    height: auto;
}