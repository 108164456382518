.Navigation {
    position: sticky;
    top : 0 ;
    z-index: 100;
    background-color: var(--accent_2);
    height: 64px ;
    box-shadow: 0px 0px 8px var(--primary) ;
}

.NavigationBar {
    margin : 0 4em ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: inherit;

}

.NavLinks {
    display: flex;
    gap : 2px
}

.NavLinks a {
    text-decoration: none;
    color : var(--primary);
    font-family: "poppins" , "sans-serif";
    font-weight: bold;
    padding : 20px ;
    border-radius: 0.25rem;
}

.NavLinks a:hover {
    background-color: var(--secondary_tint1);
}

.NavLinks a.active {
    background-color: var(--accent_1);
}

.NavLogo {
    width : 200px;
}

.NavLogo img{
    width : 180px ;
}

.NavLogo img:hover {
    width : 196px ;
}

.HamburgerMenuIcon {
    display: none;
}

.NavButtonSeperator {
    font-size: 2rem;
    color: var(--primary);
}
.NavRight {
    display: flex;
    align-items: center;
    gap : 0.5rem ;
}

.NavigationOverLayDisabled,
.NavigationOverLayEnabled {
    display: none;
    
}

@media  (max-width: 1080px) {
    .NavigationBar {
    margin : 0 1.5em ;
}

}

@keyframes OverlayOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes navigationMenu{
    0% {
        transform: translatex(100%);
        opacity: 0 ;
    }

    100% {
        opacity: 1;
    }
}



@media  (max-width: 940px) {
    .NavRight {
        display: flex;
        position: fixed;
        top : 64px ;
        right: 0px;
        flex-direction: column;
        z-index: 100;
        background-color: var(--accent_2);
        height: max(calc(100% - 68px) , 620px);
        box-shadow: -4px 4px 12px 0px var(--secondary_tint1);
        transform: scaleX(0);
        transform-origin: 100% 0;
        animation: navigationMenu 300ms ease-in-out; 
         
    }

    .NavRight.Mobile {
        transform: scaleX(1);    

    }
    .HamburgerMenuIcon {
        display: block;
    }
    

    .NavLinks{
        flex-direction: column;
        width: 100%;
    }

    .NavLinks a {
        padding : 28px 32px ;
        border-radius: 0 ;
    }

    .NavButtonSeperator {
        display: None;
    }

    .NavOrderNowButton {
        margin-top:  25%;
    }
    
    .NavigationOverLayEnabled {
        display: block;
        background-color:rgb( from var(--secondary_tint1) r g b / 80%);
        position: fixed;
        width: calc(100%);
        height: 100%;
        z-index: 99 ;
        animation: OverlayOpacity 300ms ease-in-out;
    }
}