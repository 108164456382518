.HomeSection1 {
    position: relative;
    width : 100% ;
    height : 90vh ;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
    color: var(--primary);

}

.HomeSection1LogoName {
    width : 180px ;
    border-bottom:  2px solid var(--primary); 
    padding : 0px 4rem 0.75rem ; 
    margin-bottom: 2rem ;
}

.HomeSection1ContentArea {
    width : 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap : 1.5rem ;
    position: absolute;
    bottom : 25% ;
    left : 5% ;
}

.HomeSection1Heading {
    font-size: 40px; 
    font-family: var(--font_2);
}

.HomeSection1Description {
    font-size: 20px;
    font-family: var(--font_1);
    text-align: center;
    padding: 0px 4rem ;
}

.HomeSection1CTAButtons {
    display: flex;
    width: inherit;
    justify-content: space-evenly;    
}

@media (max-width: 860px) {
    .HomeSection1 {
        background: linear-gradient(to top,rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.6) , rgba(0, 0, 0, 0) );
        height: 85vh;
    }
    .HomeSection1ContentArea {
        width : 100% ;
        left: 0;
        bottom : 10% ;
        
    }
    .HomeSection1Heading {
        font-size: 38px ;
    }
    
}
