.OrderNow {
    position: fixed;
    top : 0 ;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(from var(--secondary_tint1) r g b / 90%);
    z-index: 9999;
    color : var(--primary);
    transform-origin: 95% 1rem;
    transform: scale(0);
    transition: all 300ms ease-in-out;
    border-radius: 4rem;
    
}

.Active {
    border-radius: 0;
    transform: scale(1);
}

.OrderNowCloseIcon {
    position: absolute;
    top: 2rem;
    right: 5rem;   
}

.OrderNowIconImage {
    width: 80px;
}
.OrderNowIconImage:hover {
    transform: scale(1.1);
    width: 80px;
}

.OrderNowOptions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap : 2.5rem ;
    padding : 2rem 4rem ;
    background: rgb(from var(--secondary_tint2) r g b / 65%);
    border-radius: 16px;


}

@media (max-width: 680px) {

    .OrderNowOptions{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap : 4rem ;
        padding : 2rem 4rem ;
    } 
    .OrderNowCloseIcon {
    position: absolute;
    top: 1rem;
    right: 2rem;   
}
}