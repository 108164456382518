.ContactUsCardCallUs {
    position: relative;
    color: var(--primary) ;
    font-size: 32px;
    font-family: var(--font_1);
    width: 228px;
    height: 180px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.ContactUsCardCallUs a ,
.ContactUsCardCallUs a:hover  {
    padding : 16px 12px ;
    text-align: center;
    margin : auto ;
}
