
.CopyrightFooter {
	position: relative;
	cursor: pointer;
	font-family: 'Poppins', sans-serif;
	height: 48px;
	background-color: var(--secondary_tint1);
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	color: var(--primary);
	font-size: 14px;
    border-top: 1px solid var(--primary);
}



