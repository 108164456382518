.NavOrderNowButton{
    position: relative;
    width : 128px ;
    border: 2px solid var(--primary);
    height : 44px;
    font-family: "poppins" , "sans-serif";
    font-weight: bolder;
    overflow: hidden;
    background-color: var(--secondary_tint1);

}

.NavOrderNowButton div{
    position: absolute;
    top : -60px ;
    right: 104px;
    width : 80px ;
    height: 80px;
    border-radius: 100%;
    background-color: var(--accent_1_tint1);
}

.NavOrderNowButton span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:inherit;
    text-align: center;
    line-height: 44px ;
    color : var(--primary) ;
    cursor:pointer;
    z-index: 10;  
}

.NavOrderNowButton span:hover + div {
    top : 0px ;
    right: -10px;
    width : 320px ;
    height: 80px;
    border-radius: 0;
}

@media  (max-width: 1080px) {
    .NavigationBar {
    margin : 0 1.5em ;
}
}

@media  (max-width: 940px) {
    .NavOrderNowButton{ 
        width : 240px ;
        height : 60px;
    
    }
    .NavOrderNowButton div{
        position: absolute;
        top : -132px ;
        left: -132px;
        width : 180px ;
        height: 180px;
    }
    .NavOrderNowButton span:hover + div {
        top : -132px ;
        width : 180px ;
        height: 180px;
        border-radius: 100%;
    }

}

