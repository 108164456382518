.ContactUsCardClock {
    position: relative;
    color: var(--primary) ;
    font-family: var(--font_1);
    width: 248px;
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap : 10px ;
}

.ContactUsCardClockDataContainer {
    display: flex;
    justify-content: space-between;
    font-family: var(--font_1);
    letter-spacing: 1px;
    opacity: 0.25 ;
}

.ContactUsCardClockDataContainer.Active {
    opacity: 1;
    transform: scale(1.02);

}