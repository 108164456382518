.HomeSection2 {
    position: relative;
    color : var(--primary) ;
}

.HomeSection2Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top,  rgb(from var(--secondary) r g b / 100%) , rgb(from var(--secondary) r g b / 80%), rgb(from var(--secondary) r g b / 70%) );
    z-index: -10 ;
}
.HomeSection2ContentAreaContainer {
    width : inherit ;
    margin : 0 4rem ;
    display: flex;
    flex-direction: column ;
    align-items: center;
    padding : 2rem 0px 6rem ;
    gap : 4rem ;
}

.HomeSection2Header {
    font-family: var(--font_2);
    font-size: 3rem ;
    width: fit-content;
    border-bottom: 2px solid var(--primary);
    padding : 0.5rem 4rem ;
}

.HomeSection2Content{
    display: flex ;
    gap : 4rem ;
    font-family: var(--font_1);
    font-size: 22px;
    letter-spacing: 1px;
}

.HomeSection2Content1 {
    background-color:  rgb(from var(--secondary_tint2) r g b / 75%);
    border-radius: 1rem;
    padding : 3rem ;
    flex : 2 ;
    line-height: 2rem;
    display: flex;
    text-align: justify;
    

   
}
.HomeSection2Content2 {
    flex : 3 ;
    display: flex;
    flex-direction: column;
    justify-content: space-between ;
   
}

@media (max-width: 1080px) {
    .HomeSection2ContentAreaContainer {
        gap : 6rem ;
        margin : 0 1.5rem ;
    }

    .HomeSection2Header {
        font-size: 36px;
        padding : 0.5rem 2rem ;
    }

    .HomeSection2Content{
        flex-direction: column;
        align-items: center;
        gap : 6rem ;
    }

    .HomeSection2Content1 {
        width : 75% ;
        padding : 2.5rem ;

    }

    .HomeSection2Content2 {
        gap : 1rem ;
       
    }
}
