.ContactUs {
    position: relative;
    color: var(--primary);
    background-color: var(--secondary_tint1);
    
}

.ContactUsSection1 {
    background-image: url("./../../assets/contact_us_page_bg.jpg"); 
    background-size: cover;
    background-position: center center ;
    width: 100%; 
    
}

.ContactUsSection1Overlay {
    width : 100% ; 
    background: linear-gradient(to top, rgb(from var(--secondary_tint1) r g b / 100%),
                                        rgb(from var(--secondary_tint1) r g b / 65%),
                                        rgb(from var(--secondary_tint1) r g b / 25%) );
    padding: 4rem 0 12rem; 
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap : 1.5rem ;

}

.ContactUsSection1Heading {
    font-family: var(--font_2);
    font-size: 48px;

}

.ContactUsSection1Description {
    font-size: 28px;
    font-family: var(--font_1);
    width : 380px ;

}


.ContactUsDetailsCardSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap : 4rem ;
    position:relative;
    top : -6rem ;
    overflow :visible;
    z-index: 5;
    margin: 0 2rem;

}

.ContactUsMapSection {
    margin : 2rem 4rem 6rem ;
    overflow: hidden;
}

.ContactUsMapSectionMapFrame {
    width : 100% ;
    border-radius: 0.5rem;
    height: 480px;
}

@media  (max-width: 860px) {
    .ContactUsDetailsCardSection {
        gap: 2.5rem;
    }

    .ContactUsMapSectionMapFrame {
        width : 94% ;
        height: 75vh;
    }

    .ContactUsMapSection {
        margin : 0  0 6rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
}